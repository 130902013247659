<template>
  <div class="q-pa-sm">
    <q-card>
      <div class="row items-center q-pr-sm">
        <div class="q-pl-sm" style="min-width: 30px;">
          <search
            dense
            is-expandable
            @submit="handleSearch"
          />
        </div>

        <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
          inline-label
        >
          <q-tab
            name="all"
            icon="apps"
            :label="$t('All')"
            no-caps
          />

          <q-tab
            v-for="category in categories"
            :key="category.id"
            :name="category.id"
            :label="$t(category.name)"
            no-caps
          />
        </q-tabs>

        <q-space />

        <q-btn
          v-if="isAdministrator"
          color="light-blue-9"
          size="sm"
          icon="add"
          @click="handleAdd"
        />
      </div>

      <q-separator />

      <q-card-section>
        <div
          v-if="items.length <= 0 && !isLoading"
          class="row items-center justify-center text-center text-subtitle1"
          style="height: calc(100vh - 130px);"
        >
          {{ $t('No founded items') }}
        </div>

        <div class="row">
          <div
            v-for="item in items"
            :key="item.id"
            class="col-12 col-md-6 q-pa-md"
          >
            <q-card class="fit clickable bg-white text-dark">
              <q-card-section class="border-bottom q-pl-none q-py-xs row items-center">
                <q-legend :label="item.name" @click="handleSelect(item)" />

                <q-btn
                  v-if="isAdministrator"
                  color="transparent"
                  text-color="dark"
                  flat
                  size="sm"
                  icon="edit"
                  @click="handleOpen(item)"
                />

                <q-space />

                <q-badge :label="typeNames[item.type]" :color="typeColors[item.type]" />
              </q-card-section>

              <q-card-section class="text-caption" @click="handleSelect(item)">
                <span v-if="item.description">
                  {{ $t(item.description) }}
                </span>

                <span v-else>
                  {{ $t('No description') }}
                </span>
              </q-card-section>
            </q-card>
          </div>
        </div>

        <div
          v-if="isEnabledLoading"
          v-observe-visibility="loadItems({ page: itemsPage + 1, search, per_page: 25 })"
        />

        <div
          v-if="isLoading"
          class="text-primary col-12 row fit justify-center items-center"
          style="min-height: calc(100vh - 130px);"
        >
          <q-spinner
            color="light-blue-9"
            size="3rem"
          />
        </div>
      </q-card-section>
    </q-card>

    <report-type-modal ref="reportTypeModal" @submit="handleSubmit" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import Search from '../../components/search/Search.vue'
import ReportTypeModal from '../../components/modals/ReportTypeModal.vue'

export default {
  name: 'ReportCategories',
  components: {
    Search,
    ReportTypeModal
  },
  directives: {
    ObserveVisibility
  },
  data () {
    return {
      categories: [],
      items: [],
      itemsPage: 0,
      itemsTotalPages: 1,
      isLoading: false,
      search: '',
      tab: 'all',
      filter: [{ type: 'eq', field: 'state', value: 'active' }],
      typeNames: {
        static: 'DOC',
        dynamic: 'LIVE'
      },
      typeColors: {
        static: 'info',
        dynamic: 'teal'
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAdministrator'
    ]),
    isEnabledLoading () {
      return this.itemsPage < this.itemsTotalPages & !this.isLoading
    }
  },
  watch: {
    tab (value) {
      this.filter = [{ type: 'eq', field: 'state', value: 'active' }]
      this.items = []

      if (value !== 'all') {
        this.filter.push({ type: 'eq', field: 'category', value })
      }

      this.loadItems({ page: 1, search: this.search })
    }
  },
  mounted () {
    this.loadCategories()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setReportType'
    ]),
    handleOpen (item) {
      this.$refs.reportTypeModal.open(item)
    },
    handleAdd () {
      this.$refs.reportTypeModal.open({})
    },
    handleSubmit (item) {
      let isFounded = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          isFounded = true
          return item
        }

        return x
      })

      if (isFounded) {
        this.items = [item, ...this.items]
      }
    },
    loadCategories () {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      return this.$service.category.getAll(query)
        .then(({ items }) => {
          this.categories = items
        })
    },
    handleSearch (search) {
      this.search = search
      return this.loadItems({ search, page: 1 })
    },
    handleSelect (item) {
      if (item.type === 'dynamic') {
        this.setReportType(item)
        this.$router.push(`/documents/reports/entity/${item.id}`)
        return
      }

      this.$router.push({ path: '/documents/reports/reports', query: { per_page: 25, page: 1, filter: [{ type: 'eq', field: 'reportType', value: item.id }] } })
    },
    loadItems (params = {}) {
      const query = {
        per_page: 25,
        page: 1,
        filter: this.filter,
        ...params
      }

      this.isLoading = true
      return this.$service.reportType.getAll(query)
        .then(({ items, page, totalPages }) => {
          this.itemsPage = page
          this.itemsTotalPages = totalPages

          if (page <= 1) {
            this.items = items
          } else {
            this.items = [
              ...this.items,
              ...items
            ]
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
